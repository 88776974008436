import styled from 'styled-components';

import { breakpoints } from 'utils/mixins';
import { container, fontSize, space } from 'theme/sizes';
import { toRem } from 'utils/mixins';
import { brand, neutral } from 'theme/colors';

export const Wrapper = styled.ul`
  max-width: ${container.xsmall};
  width: 100%;
  margin: ${space.m} auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 ${space.l};
    text-align: center;

    &:not(:last-child) {
      margin-bottom: ${space.xl};
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${space.l};
      height: ${space.l};
      border-radius: 50%;
      background-color: ${brand.secondary};
      font-size: ${fontSize.l};
      color: ${neutral.neu09};
      margin-bottom: ${space.s};
    }

    p {
      text-align: center;
    }
  }

  @media ${breakpoints.laptop} {
    li {
      width: calc(100% / 3);
      padding: 0 ${space.xl};
      text-align: center;
    }
  }

  @media ${breakpoints.bigDesktop} {
    max-width: ${container.default};
  }
`;
