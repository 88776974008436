import React from 'react';

import HTMLReactParser from 'html-react-parser';
import { IoPeopleOutline, IoFingerPrint, IoSchool } from 'react-icons/io5';
import {
  MdOutlineScience,
  MdOutlineWbSunny,
  MdRestaurantMenu,
} from 'react-icons/md';
import { SiFuturelearn } from 'react-icons/si';
import { BiExpand, BiNotepad, BiTrendingUp, BiDesktop } from 'react-icons/bi';
import { BsLightbulb, BsCalendarDate, BsPersonPlusFill } from 'react-icons/bs';
import {
  FaUniversity,
  FaTools,
  FaDatabase,
  FaEuroSign,
  FaChalkboardTeacher,
  FaHandsHelping,
} from 'react-icons/fa';
import { CgInsertAfter } from 'react-icons/cg';

import { Wrapper } from './IconList.styles';

const IconList = (props) => {
  //PROPS
  const { data } = props;
  //PROPS

  const getIcon = (string) => {
    switch (string) {
      case 'team':
        return <IoPeopleOutline />;
      case 'cientific':
        return <MdOutlineScience />;
      case 'progression':
        return <SiFuturelearn />;
      case 'dimension':
        return <BiExpand />;
      case 'project':
        return <IoFingerPrint />;
      case 'avaliation':
        return <BiNotepad />;
      case 'couseling':
        return <BsLightbulb />;
      case 'education':
        return <FaUniversity />;
      case 'planning':
        return <BsCalendarDate />;
      case 'learn':
        return <IoSchool />;
      case 'tools':
        return <FaTools />;
      case 'database':
        return <FaDatabase />;
      case 'integration':
        return <CgInsertAfter />;
      case 'business':
        return <MdOutlineWbSunny />;
      case 'recruitment':
        return <BsPersonPlusFill />;
      case 'cost':
        return <FaEuroSign />;
      case 'market':
        return <BiTrendingUp />;
      case 'webinars':
        return <BiDesktop />;
      case 'workshops':
        return <FaChalkboardTeacher />;
      case 'help':
        return <FaHandsHelping />;
      case 'menu':
        return <MdRestaurantMenu />;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      {data.map((item, index) => {
        const { recruIcon, recruText, recruTitle } = item;

        return (
          <li key={index}>
            <span>{getIcon(recruIcon)}</span>
            <h4>{recruTitle}</h4>
            {recruText && HTMLReactParser(recruText)}
          </li>
        );
      })}
    </Wrapper>
  );
};

export default IconList;
