import styled from 'styled-components';

import { container, space, fontSize } from 'theme/sizes';
import { brand, neutral, secondary } from 'theme/colors';
import { toRem, breakpoints } from 'utils/mixins';
import { p, h2, h1, h3 } from 'theme/typography';

export const Wrapper = styled.section`
  background-color: ${(props) =>
    props.type === 'partners'
      ? neutral.neu07
      : props.type === 'recruitment'
      ? '#3f9371'
      : props.type === 'services'
      ? '#79ADDC'
      : brand.secondary};
  width: 100%;
  padding: ${space.s};
  margin-top: ${space.xl};
`;


export const Content = styled.div`
  max-width: ${container.xsmall};
  margin: ${space.l} auto;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .content__left {
    flex: 1;
    text-align: center;

    h3 {
      ${h2}
      margin: 0;
      margin-bottom: ${space.xxs};
      font-weight: bold;
      color: ${(props) =>
        props.type === 'partners' || props.type === 'recruitment'
          ? neutral.neu01
          : neutral.neu09};
    }

    p {
      max-width: ${container.xsmall};
      margin-bottom: ${space.m};
      color: ${(props) =>
        props.type === 'partners' || props.type === 'recruitment'
          ? neutral.neu01
          : neutral.neu09};
    }
  }

  .content__right {
    a {
      font-size: ${fontSize.s};
      transition: 250ms;
      padding: ${space.xsmall} ${space.xs};
      background: ${(props) =>
        props.type === 'recruitment' ? neutral.neu01 : neutral.neu09};
      color: ${(props) =>
        props.type === 'partners'
          ? neutral.neu01
          : props.type === 'recruitment'
          ? '#3f9371'
          : brand.fourth};
      border: 1px solid
        ${(props) =>
          props.type === 'partners' || props.type === 'recruitment'
            ? neutral.neu01
            : neutral.neu09};

      &:hover {
        background-color: ${(props) =>
          props.type === 'partners' ? neutral.neu01 : 'transparent'};
        color: ${(props) =>
          props.type === 'partners'
            ? neutral.neu09
            : props.type === 'recruitment'
            ? neutral.neu01
            : neutral.neu09};
      }
    }
  }

  @media ${breakpoints.laptop} {
    .content__left {
      text-align: center;
    }

    .content__right {
      flex-shrink: 1;
    }
  }

  @media ${breakpoints.bigDesktop} {
    max-width: ${container.small};
  }
`;

export const WrapperImage = styled.div`
  background-color: green;
  width: 100%;

  .gatsby-image-wrapper {
    width: 100vw;
    height: 100%;
    background-color: black;

    picture {
      opacity: 0.7;
    }
  }

  // 600px
  @media ${breakpoints.phablet} {
  }
  // 768px
  @media ${breakpoints.tablet} {
  }
  // 850px
  @media ${breakpoints.tabletSpec} {
  }
  // 1024px
  @media ${breakpoints.laptop} {
  }
  // 1120px
  @media ${breakpoints.desktop} {
  }
  // 1440px
  @media ${breakpoints.bigDesktop} {
  }
  // 1920px
  @media ${breakpoints.largeDesktop} {
  }
`;

export const ContentImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${toRem(150)} 0;
  margin: 0 auto;
  max-width: ${container.default};
  color: ${neutral.neu01};

  h3 {
  }

  p {
    text-align: center;
    font-size: 8vh;
    line-height: 8vh;
    font-weight: bold;
    margin: ${toRem(60)} 0;
  }
`;
