import React from 'react';

import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Parallax, Background } from 'react-parallax';
import HTMLReactParser from 'html-react-parser';

import { ButtonSecondary } from 'components/button';

import { Wrapper, WrapperImage, Content, ContentImage } from './CTA.styles';

const CTA = (props) => {
  //PROPS
  const {
    data: { ctaSubtitle, ctaTitle, type, ctaCta, ctaImage },
  } = props;
  //PROPS

  if (ctaImage) {
    return (
      <WrapperImage>
        <Parallax strength={300}>
          <Background className="custom-bg">
            <GatsbyImage
              image={ctaImage?.localFile?.childImageSharp?.gatsbyImageData}
              alt={ctaImage?.altText}
            />
          </Background>
          <ContentImage>
            <h3>{ctaTitle}</h3>
            <p>{ctaSubtitle}</p>
            <Link to={ctaCta.url}>{ctaCta.title}</Link>
          </ContentImage>
        </Parallax>
      </WrapperImage>
    );
  } else {
    return (
      <Wrapper type={type}>
        <Content type={type}>
          <div className="content__left">
            <h3>{ctaTitle}</h3>
            {ctaSubtitle && HTMLReactParser(ctaSubtitle)}
          </div>
          <div className="content__right">
            <ButtonSecondary to={ctaCta.url}>{ctaCta.title}</ButtonSecondary>
          </div>
        </Content>
      </Wrapper>
    );
  }
};

export default CTA;
