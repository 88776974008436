import styled from 'styled-components';

import { container, space, zIndex } from 'theme/sizes';
import { h2 } from 'theme/typography';

import { breakpoints, gridSize } from 'utils/mixins';

export const Wrapper = styled.div`
  max-width: ${container.xsmall};
  margin: ${space.xl} auto 0;
  padding: ${space.s};
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.imageFirst ? 'space-between' : 'space-between'};
  flex-wrap: wrap;

  @media ${breakpoints.bigDesktop} {
    max-width: ${container.default};
  }
`;

export const LottieContainer = styled.div`
  width: 100%;
  position: relative;

  .gatsby-image-wrapper {
    object-fit: contain;
  }

  @media ${breakpoints.tablet} {
    width: ${gridSize(4)};
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: ${space.s} 0;
  order: 2;
  h3 {
    ${h2}
  }

  @media ${breakpoints.tablet} {
    order: unset;
    padding: ${space.s};
    width: ${gridSize(4)};
  }
`;
