import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import HTMLReactParser from 'html-react-parser';

import { Wrapper, Content, LottieContainer } from './Feature.styles';

const Feature = (props) => {
  //PROPS
  const {
    featureImageFirst,
    featureSubtitle,
    featureText,
    featureTitle,
    featureImage,
  } = props;
  //PROPS

  return (
    <Wrapper imageFirst={featureImageFirst}>
      {featureImageFirst ? (
        <>
          <LottieContainer>
            <GatsbyImage
              image={featureImage?.localFile?.childImageSharp?.gatsbyImageData}
              alt={featureImage?.altText}
            />
          </LottieContainer>

          <Content>
            <h4>{featureTitle}</h4>
            <h3>{featureSubtitle}</h3>
            {featureText && HTMLReactParser(featureText)}
          </Content>
        </>
      ) : (
        <>
          <Content>
            <h4>{featureTitle}</h4>
            <h3>{featureSubtitle}</h3>
            {featureText && HTMLReactParser(featureText)}
          </Content>
          <LottieContainer>
            <GatsbyImage
              image={featureImage?.localFile?.childImageSharp?.gatsbyImageData}
              alt={featureImage?.altText}
            />
          </LottieContainer>
        </>
      )}
    </Wrapper>
  );
};

export default Feature;
